import React from "react";

const SpecificPriceCard = ({ data, item, openData }) => {
  const pro = item.product_data;
  const m_price =
    pro.p_price && pro.p_price.length > 0
      ? pro.p_price[0].value
      : parseFloat(pro.price);

  let catlog = item.catdata;

  let discounted_price = catlog.flattDiscount
    ? m_price - parseFloat(catlog.discount)
    : (m_price * (100 - parseFloat(catlog.discount))) / 100;

  let sp_price = 0;
  let spdata = null;

  if (item.sp !== null && item.sp !== "") {
    spdata = item.sp;

    sp_price = spdata.flattDiscount
      ? m_price - parseFloat(spdata.discount)
      : (m_price * (100 - parseFloat(spdata.discount))) / 100;
    {
      /* catlog.discount = parseFloat(spdata.discount);
                          catlog.flattDiscount = spdata.flattDiscount; */
    }
  }

  return (
    <>
      <div className="card product-card">
        <div className="row p-2">
          <div className="col-4 d-flex align-items-center">
            <div className="product_card_image">
              <img
                src={data.p_gallery_image[0].link}
                className="p_card-img-top img-fluid "
                alt="..."
              />
            </div>
          </div>
          <div className="col-8">
            <div className="product-card-container">
              <div className="prod-card-title d-flex align-items-center">
                <div className="prod-card-title me-2">{data.p_name}</div>
                <span className="prod-card-brand">{data.brand}</span>
              </div>
              <div className="prod-card-category d-flex">
                <div className="prod-card-category me-2">
                  <span className="cata-mrp">MRP : </span>
                  <span className="mrp-data">
                    {parseFloat(m_price).toFixed(2)}
                  </span>
                </div>
              </div>
              <div className="prod-card-attr">
                <span className="cata-price">
                  Catalog Price: ₹{parseFloat(discounted_price).toFixed(2)}{" "}
                </span>
              </div>

              {item.sp !== null && item.sp !== "" && (
                <div className="prod-card-attr">
                  <span className="cata-sp-price">
                    Specific Price: ₹{parseFloat(sp_price).toFixed(2)}{" "}
                  </span>
                </div>
              )}
              <span className="dis">
                {spdata !== null
                  ? spdata.flattDiscount === true
                    ? "₹ " + spdata.discount
                    : spdata.discount + "%"
                  : ""}
              </span>
            </div>
          </div>
        </div>

        <div className="dropdown  menu_btn">
          <button
            type="button"
            className="btn p-0 dropdown-toggle hide-arrow"
            data-bs-toggle="dropdown"
          >
            <i className="bx bx-dots-vertical-rounded prod"></i>
          </button>
          <div className="dropdown-menu">
            {item.sp !== null && item.sp !== "" ? (
              <p className="dropdown-item">Reset Price</p>
            ) : (
              <p className="dropdown-item" onClick={()=>{
                 openData({
                   name: pro.p_name,
                   cat_obj: item.catdata,
                   mrp: m_price,
                 });
              }}>
                Change Price
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecificPriceCard;
