import { useState } from "react";
import React from "react";

export default function MyButton(props) {
  const [mystyle, setMystyle] = useState({
    left: "0%",
  });
  const setActive = (type) => {
    console.log(type);
    if (type === "active") {
      const data = {
        left: "0%",
      };
      setMystyle(data);
    } else if (type === "pending") {
      const data = {
        left: "33.33%",
      };
      setMystyle(data);
    } else if (type === "block") {
      const data = {
        left: "66.67%",
      };
      setMystyle(data);
    }
  };
  return (
    <>
      <div className="wraper">
        <div className="btn_group">
          <p
            className="btn_two  text-black"
            onClick={() => {
              setActive("active");
            }}
            id="active"
          >
            <span>Active</span>
            <span className="btn_bg" style={mystyle}></span>
          </p>
          <p
            className="btn_one slid_btn_active text-black"
            onClick={() => {
              setActive("pending");
            }}
            id="pending"
          >
            <span>Pending</span>
          </p>
          <p
            className="btn_three"
            onClick={() => {
              setActive("block");
            }}
            id="block"
          >
            <span>Block</span>
          </p>
        </div>
      </div>
    </>
  );
}


export const CartButton = ({
  btnShow = true,
  show = false,
  callback = (type, value) => {},
  initialValue = 0, // The initial value for the quantity
  showDelete = false,
}) => {
  // Use local state to track the quantity of the product
  const [quantity, setQuantity] = useState(initialValue);
  const [isShown, setIsShown] = useState(show);

  const handleClick = (type, val) => {
    if (btnShow) {
      // Handle increment or decrement
      if (type === "plus") {
        setQuantity(quantity + 1); // Increase quantity
        callback(type, quantity + 1);
      } else if (type === "min" && quantity > 0) {
        setQuantity(quantity - 1); // Decrease quantity if greater than 0
        callback(type, quantity - 1);
      }
    }
  };

  return (
    <div className="buttonBadges">
      {isShown ? (
        <>
          <span
            className="mx-1 px-2 py-1"
            style={{ cursor: "pointer", textAlign: "center" }}
            onClick={() => handleClick("min", 1)} // Decrease item count
          >
            {btnShow &&
              (showDelete === true ? <i className="fa fa-trash"></i> : "-")}
          </span>

          <span
            className="mx-1 px-2 py-1"
            style={{ cursor: "pointer", textAlign: "center" }}
            onClick={() => handleClick("min", 0)} // Reset or handle other action
          >
            {quantity}
          </span>

          <span
            className="mx-1 px-2 py-1"
            style={{ cursor: "pointer", textAlign: "center" }}
            onClick={() => handleClick("plus", 1)} // Increase item count
          >
            {btnShow && "+"}
          </span>
        </>
      ) : (
        <span
          className="mx-3 px-3 py-1"
          style={{ cursor: "pointer", textAlign: "center" }}
          onClick={() => {
            setIsShown(true); // Update the local state to show buttons
            callback("addCart", 0); // Trigger add to cart action
          }}
        >
          Add
        </span>
      )}
    </div>
  );
};