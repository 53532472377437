import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ConfirmAlert from "../componant/ConfirmAlert/ConfirmAlert";
import BulkPriceChange from "../componant/ProductBulkChange/ProductBulkchangeModal";
import ManageInventory from "../context/Product/ManageInventory";
import productContext from "../context/Product/productContext";
import MyButton from "../componant/MyButton";
import CustomButton from "../componant/CustomButton";
import ShowProImage from "./modal/ShowProImage";
import LoadMore from "../componant/process/LoadMore";
import InfiniteScroll from "react-infinite-scroll-component";
import Search_Query from "../componant/search_query/Search_Query";
import AddNewProduct from "./AddNewProduct";
import Categoryfilter from "../componant/Categoryfilter";
import ProductCard from "../MobilePages/ProductCard";
import SettingContext from "../context/Setting/SettingContext";

export default function Products(props) {
  const mycontext = useContext(productContext);
  const { width } = useContext(SettingContext);
  const {
    product,
    totalCount,
    getAllProduct,
    updateProduct,
    deleteProduct,
    out_of_stock,
    show_and_hide,

    setEditObject,
  } = mycontext;
  const [p_id, setp_id] = useState("");
  useEffect(() => {
    getAllProduct();
  }, []);

  const [preloader, setPreloader] = useState("");
  // if (localStorage.getItem("com_id") === null) {
  //   return <Navigate to="/mycompanies" />;
  // }

  const [modal, setModal] = useState(false);
  const style = {
    background: "#00000080",
    display: "block",
  };
  const openData = (id) => {
    setModal(!modal);
    setp_id(id);
  };
  // console.log(product);

  const [myfiles, setfiles] = useState({
    files: [],
  });

  const selectHandler = (e) => {
    e.preventDefault();
    setfiles({ files: [...myfiles.files, ...e.target.files] });
  };

  const uploadimage = async (e) => {
    e.preventDefault();
    const data = new FormData();
    for (let i = 0; i < myfiles.files.length; i++) {
      const element = myfiles.files[i];
      data.append("file", element);
    }
    const res = await updateProduct(data, p_id);
    if (res.status === "Failed") {
      props.showAlert(res.msg, "danger");
    } else if (res.status === "Success") {
      props.showAlert("Image updated", "success");
    }

    setModal(!modal);
    setfiles({ files: [] });
  };

  // search filter started form here
  const [query, setQuery] = useState("");
  const search = (data) => {
    return data.filter((item) => item.p_name.toLowerCase().includes(query));
  };

  const [productData, setproductData] = useState({});
  const [invnetoryModal, setInventoryModal] = useState(false);
  const manageInventory = (data) => {
    setproductData(data);
    setInventoryModal(!invnetoryModal);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const openDelete = (id) => {
    setDeleteModal(!deleteModal);
    setDeleteId(id);
  };
  const deleteProductFunction = async () => {
    const res = await deleteProduct(deleteId);
    if (res.status === "Success") {
      props.showAlert("Product Deleted", "success");
      setDeleteModal(!deleteModal);
    } else {
      props.showAlert(res.msg, "danger");
    }
  };

  // bulkPriceModal
  const [bulkPriceModal, setbulkPriceModal] = useState(false);
  const openBulkPrice = () => {
    setbulkPriceModal(!bulkPriceModal);
  };

  const [p_filter, setFilter] = useState(true);
  const filterfunction = (title) => {
    if (title === "Active") {
      setFilter(true);
    } else if (title === "Hidden") {
      setFilter(false);
    }
  };

  const btn_data = [
    {
      title: "Active",
    },
    {
      title: "Hidden",
    },
  ];

  // show image modal
  const [showImage, setShowImage] = useState(false);
  const [cureentImageList, setCurrentImageList] = useState([]);
  const [loadMore, setLoadMore] = useState(true);
  const [page, setPage] = useState(1);
  const [cat_filter, set_cat_filter] = useState(null);

  const cat_filter_func = (id) => {
    set_cat_filter(id);
    setPage(1);

    getAllProduct(false, 1, "", p_filter, (l_length) => {}, id);
  };
  const fetchFunction = (que) => {
    console.log(
      "load more function called" + product.length + " - " + totalCount
    );
    if (product.length === totalCount) {
      setLoadMore(false);
    } else {
      setLoadMore(true);
      setPage(page + 8);
      getAllProduct(
        true,
        page + 8,
        que,
        p_filter,
        (l_length) => {
          if (l_length === 0) {
            setLoadMore(false);
          }
        },
        cat_filter
      );
    }
  };

  useEffect(() => {
    setPage(1);
    getAllProduct(false, 1, "", p_filter, (l_length) => {
      // fetchFunction(query);
      setLoadMore(true);
    });
  }, [p_filter]);

  const navigation = useNavigate();

  const On_Delete = (id) => {
    props.showCon(
      "Are you sure, you want to delete this product?",
      true,
      async () => {
        openDelete(id);
      }
    );
  };

  const Pro_data = product.map((pro, i) => {
    const subCategory =
      pro.cat_id &&
      pro.cat_id.sub_items.length > 0 &&
      pro.cat_id.sub_items.filter((sub_cat) => {
        return sub_cat._id === pro.sub_cat_id;
      });

    const pkg_level = pro.pkg_level;
    let moq_level = null;
    if (pkg_level == 111 || pkg_level == 0) {
      moq_level = pro.unit_name;
    } else if (pkg_level == 211) {
      moq_level = pro.pkg_unit;
    } else if (pkg_level == 221) {
      // unit_add_btn.setVisibility(View.GONE);
      // pkg_add_btn.setVisibility(View.GONE);
      // mpkg_add_btn.setVisibility(View.VISIBLE);
      moq_level = pro.mpkg_unit;
    } else {
      moq_level = "null";
    }

    return (
      <>
        {width < 767 ? (
          <ProductCard
            data={pro}
            show_and_hide={show_and_hide}
            setEditObject={setEditObject}
            navigation={navigation}
            out_of_stock={out_of_stock}
            openDelete={openDelete}
            show_con={On_Delete}
          />
        ) : (
          <tr key={pro._id}>
            <td className="" style={{ textAlign: "center" }}>
              {/* <div className="demo-inline-spacing"> */}

              {/* product visibiliyt button */}

              <div className="dropdown">
                <button
                  type="button"
                  className="btn p-0 dropdown-toggle hide-arrow"
                  data-bs-toggle="dropdown"
                >
                  <i className="bx bx-dots-vertical-rounded"></i>
                </button>
                <div className="dropdown-menu">
                  {pro.visibility ? (
                    <>
                      <p
                        className="dropdown-item"
                        onClick={() => {
                          navigation("/editProduct", {
                            id: pro._id,
                          });
                          setEditObject("edit", pro);
                        }}
                      >
                        <i className="bx bx-edit-alt me-1"></i> Edit
                      </p>
                      <p
                        className="dropdown-item"
                        onClick={() => {
                          show_and_hide(pro._id, "hide");
                        }}
                      >
                        <i className="bx bx-hide me-1"></i> Hide
                      </p>
                      {pro.hide_inventory ? (
                        <p
                          className="dropdown-item"
                          onClick={() => {
                            out_of_stock(pro._id, "show");
                          }}
                        >
                          <i className="bx bx-hide me-1"></i> back in stock
                        </p>
                      ) : (
                        <p
                          className="dropdown-item"
                          onClick={() => {
                            out_of_stock(pro._id, "hide");
                          }}
                        >
                          <i className="bx bx-hide me-1"></i> out of stock
                        </p>
                      )}
                      <p
                        className="dropdown-item"
                        onClick={() => {
                          props.showCon(
                            "Are you sure, you want to delete this product?",
                            true,
                            async () => {
                              openDelete(pro._id);
                            }
                          );
                        }}
                      >
                        <i className="bx bx-trash me-1"></i> Delete
                      </p>
                    </>
                  ) : (
                    <p
                      className="dropdown-item"
                      onClick={() => {
                        show_and_hide(pro._id, "show");
                      }}
                    >
                      <i className="bx bx-show me-1"></i> Show
                    </p>
                  )}
                </div>
              </div>

              {/* </div> */}
            </td>
            <td
              onClick={() => {
                setShowImage(!showImage);
                setCurrentImageList(pro.p_gallery_image);
              }}
            >
              <img
                src={
                  pro.p_gallery_image.length !== 0
                    ? pro.p_gallery_image[0].link
                    : "../assets/img/avatars/5.png"
                }
                alt="Avatar"
                width="60"
                height="60"
                className="rounded-circle customer_image my-auto"
              />
              <p
                className={pro.hide_inventory ? "d-block" : "d-none"}
                style={{
                  fontSize: "10px",
                  color: "red",
                  position: "absolute",
                  zIndex: "1",
                  background: "white",
                }}
              >
                out of stock
              </p>
            </td>
            <td style={{ textWrap: "wrap" }}>{pro.p_name}</td>
            <td>{pro.brand}</td>
            <td>
              {pro.cat_id ? pro.cat_id.cat_name : "N/A"}
              <small>
                {" "}
                (
                {subCategory && subCategory.length > 0
                  ? subCategory[0].name
                  : "N/A"}
                )
              </small>
            </td>
            <td>
              {pro.p_price.length !== 0 ? (
                <ul className="attrContainer">
                  {pro.p_price.map((attr) => {
                    const attr_value = parseFloat(attr.value).toFixed(2);
                    const attr_pkg = parseFloat(attr_value * pro.pkg).toFixed(
                      2
                    );

                    const attr_mpkg = parseFloat(
                      attr_value * pro.pkg * pro.mpkg
                    ).toFixed(2);
                    return (
                      <li key={attr._id}>
                        <span className="unitSpan">
                          {attr.name}{" "}
                          <ul className="attrprice ">
                            {pkg_level !== 221 && (
                              <>
                                <li>
                                  ₹ {attr_value}/{" "}
                                  {pro.unit_value + " " + pro.unit_name}
                                </li>
                                <li>
                                  ₹ {attr_pkg}/ {pro.pkg_unit} ({pro.pkg}{" "}
                                  {pro.unit_name})
                                </li>
                              </>
                            )}
                            <li>
                              ₹ {attr_mpkg}/ {pro.mpkg_unit} ({pro.mpkg}{" "}
                              {pro.pkg_unit})
                            </li>
                          </ul>
                        </span>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <div>
                  <ul className="attrContainer">
                    <li>
                      <span className="unitSpan">
                        <ul className="attrprice p-0 ">
                          {pkg_level !== 221 && (
                            <>
                              <li>
                                ₹ {parseFloat(pro.price).toFixed(2)}/{" "}
                                {pro.unit_value + " " + pro.unit_name}
                              </li>
                              {parseInt(pro.pkg) !== 0 && (
                                <li>
                                  ₹ {parseFloat(pro.price * pro.pkg).toFixed(2)}
                                  / {pro.pkg_unit} ({pro.pkg} {pro.unit_name})
                                </li>
                              )}
                            </>
                          )}

                          {parseInt(pro.mpkg) !== 0 && (
                            <li>
                              ₹{" "}
                              {parseFloat(
                                pro.price * pro.pkg * pro.mpkg
                              ).toFixed(2)}
                              / {pro.mpkg_unit} ({pro.mpkg} {pro.pkg_unit})
                            </li>
                          )}
                        </ul>
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </td>

            <td>
              {pro.p_price.length !== 0 ? (
                <ul className="attrContainer">
                  {pro.p_price.map((attr) => {
                    const inv = attr.inventory;

                    const inv_pkg = parseInt(inv / pro.pkg);

                    const inv_mpk = parseInt(inv / (pro.pkg * pro.mpkg));

                    return (
                      <li key={attr._id}>
                        <span className="unitSpan">
                          {attr.name}{" "}
                          <ul className="attrprice ">
                            <li>
                              {inv} {pro.unit_name}
                            </li>
                            <li>
                              {inv_pkg} {pro.pkg_unit}
                            </li>
                            <li>
                              {inv_mpk} {pro.mpkg_unit}
                            </li>
                          </ul>
                        </span>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <>
                  <ul className="attrContainer">
                    <li>
                      <span className="unitSpan">
                        <ul className="attrprice p-0 ">
                          <li>{pro.inventory + " " + pro.unit_name}</li>
                          {parseInt(pro.pkg) !== 0 && (
                            <li>
                              {parseInt(pro.inventory / pro.pkg) +
                                " " +
                                pro.pkg_unit}
                            </li>
                          )}
                          {parseInt(pro.mpkg) !== 0 && (
                            <li>
                              {parseInt(pro.inventory / (pro.pkg * pro.mpkg)) +
                                " " +
                                pro.mpkg_unit}
                            </li>
                          )}
                        </ul>
                      </span>
                    </li>
                  </ul>
                </>
              )}
            </td>

            <td>
              {pro.p_sku === "" && pro.barcode === "" ? (
                "N/A"
              ) : pro.p_sku === "" ? (
                <>
                  "N/A" <br />
                  <small>{pro.barcode === "" ? "" : pro.barcode}</small>
                </>
              ) : (
                <>
                  {pro.p_sku}
                  <br /> <small>{pro.barcode === "" ? "" : pro.barcode}</small>
                </>
              )}
            </td>

            <td>
              {pro.m_o_q} {moq_level}
            </td>
            <td
              style={{
                textWrap: "wrap",
                fontSize: "0.75em",
              }}
            >
              {pro.description}
            </td>
            <td
              style={{
                textWrap: "wrap",
                fontSize: "0.75em",
              }}
            >
              {pro.accept_order
                ? "Accepting orders while out of stock."
                : "Not accepting orders while out of stock."}
            </td>
          </tr>
        )}
      </>
    );
  });

  return (
    <>
      {showImage && (
        <ShowProImage
          openData={() => {
            setShowImage(!showImage);
          }}
          images={cureentImageList}
        />
      )}

      {deleteModal && (
        <ConfirmAlert
          open={openDelete}
          style={style}
          heading="Are you Sure want to delete this product"
          confirm={deleteProductFunction}
        />
      )}

      {modal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Set Image For Product
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <form method="post" onSubmit={uploadimage}>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="">Choose Image</label>
                    <input
                      type="file"
                      multiple
                      className="form-control"
                      name=""
                      id=""
                      aria-describedby="helpId"
                      placeholder=""
                      onChange={selectHandler}
                    />
                    <small id="helpId" className="form-text text-muted">
                      Image size should be smaller than 5 MB
                    </small>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-primary orange-btn me-2 mb-2"
                  >
                    Upload Images
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {invnetoryModal && (
        <ManageInventory open={manageInventory} data={productData} />
      )}

      {bulkPriceModal && (
        <BulkPriceChange
          open={openBulkPrice}
          showAlert={props.showAlert}
          reload={getAllProduct}
        />
      )}

      <div className="content-wrapper product_page">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="fixed-button">
            <Link
              to="/addNewProduct"
              className="btn btn-sm fixed_button btn-outline-primary d-none"
            >
              + Add
            </Link>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className=" c_heading">
                <div className="HEAF d-flex justify-content-between">
                  <div className="pro_title d-flex align-items-center">
                    <span className="me-2">All Products ({totalCount})</span>
                    <div
                      className={`dropdown position-relative  menu_btn ${
                        width > 768 && "d-none"
                      }`}
                      style={{ top: 0, right: 0 }}
                    >
                      <button
                        type="button"
                        className="btn p-0 dropdown-toggle hide-arrow"
                        data-bs-toggle="dropdown"
                      >
                        <i className="bx bx-down-arrow me-1"></i>
                      </button>
                      <div className="dropdown-menu">
                        <p
                          className="dropdown-item"
                          onClick={() => {
                            filterfunction("Active");
                            // show_and_hide(data._id, "show");
                          }}
                        >
                          Active
                        </p>
                        <p
                          className="dropdown-item"
                          onClick={() => {
                            filterfunction("Hidden");
                            // show_and_hide(data._id, "show");
                          }}
                        >
                          Hidden
                        </p>
                      </div>
                    </div>
                  </div>

                  {width < 768 && (
                    <div className="dropdown  menu_btn">
                      <button
                        type="button"
                        className="btn p-0 dropdown-toggle hide-arrow"
                        data-bs-toggle="dropdown"
                      >
                        <i className="bx bx-dots-vertical-rounded prod"></i>
                      </button>
                      <div className="dropdown-menu">
                        <p
                          className="dropdown-item"
                          onClick={() => {
                            setbulkPriceModal(!bulkPriceModal);
                          }}
                        >
                          <i className="bx bx-show me-1"></i> Bulk Price Change
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className="heading_buttons"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {/* <MyButton /> */}
                  {width > 768 && (
                    <CustomButton
                      buttondata={btn_data}
                      filterfunction={filterfunction}
                      widthM={250}
                      m_right={20}
                    />
                  )}
                  <Link
                    to="/addNewProduct"
                    className="btn btn-sm "
                    style={{
                      background: "#ef6817",
                      color: "white",
                      fontWeight: "bold",
                      width: "100%",
                      borderRadius: "0.5rem",
                      cursor: "pointer",
                      paddingTop: 7,
                      paddingBottom: 7,
                      paddingLeft: 30,
                      paddingRight: 30,
                    }}
                  >
                    + Add
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-12 my-4 ">
              <Categoryfilter
                clickFunc={(id) => {
                  cat_filter_func(id);
                }}
              />
            </div>
            <div className="col-lg-12 mb-4 ">
              <div
                className="card"
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <h5 className="card-header">
                  <div className="row align-items-center">
                    <div className="col-lg-6 font-bold">
                      <ul
                        className={`slider_button slider-white plain-orange ${
                          width < 768 ? "d-none" : "d-flex"
                        } my-2`}
                      >
                        <li className="slider-active">Bulk Upload Products</li>
                        <li
                          className="slider-active mx-2"
                          onClick={() => {
                            setbulkPriceModal(!bulkPriceModal);
                          }}
                        >
                          Bulk Price Change
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      {/* <input
                        type="text"
                        className="form-control search"
                        placeholder="Search by Product Name, sku, Status, etc..."
                        onChange={(e) => setQuery(e.target.value)}
                      /> */}

                      <Search_Query
                        placeholder="Search Products ..."
                        value={query}
                        show={query !== ""}
                        onQuery={setQuery}
                        clear_data={() => {
                          setQuery("");

                          getAllProduct(false, 1, "", p_filter, (l) => {
                            setPage(1);
                            setLoadMore(true);
                            // fetchFunction("");
                          });
                        }}
                        search_click={() => {
                          getAllProduct(false, 1, query);
                          setPage(1);
                          setLoadMore(true);
                        }}
                      />
                    </div>
                  </div>
                </h5>

                <div className="table-responsive text-nowrap mb-1">
                  <InfiniteScroll
                    style={{ overflowX: "auto" }}
                    hasMore={loadMore}
                    next={() => {
                      fetchFunction(query);
                    }}
                    dataLength={product.length}
                    loader={
                      <>
                        <div
                          className="loader"
                          style={{
                            position: "relative",
                            width: 40,
                            height: 40,
                          }}
                        ></div>
                        <span>
                          If not loaded data{" "}
                          <strong
                            onClick={() => {
                              // fetchMoreData(page + 8, filter_type);
                            }}
                          >
                            Click Here
                          </strong>
                        </span>
                      </>
                    }
                  >
                    {/* responsive logic */}

                    {width < 767 ? (
                      Pro_data
                    ) : (
                      <table className="table table-hover">
                        <thead className="order_history_header">
                          <tr>
                            <th className="">Action</th>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Brand</th>
                            <th>Category ( Sub Category )</th>
                            <th>Price/Variation</th>
                            <th>Inventory</th>
                            <th>P.Sku/Barcode</th>
                            <th>M.O.Q</th>
                            <th>Description</th>

                            <th></th>
                            {/* <th>Inventory</th> */}
                          </tr>
                        </thead>
                        <tbody className="table-border-bottom-0 order_history_body">
                          {Pro_data}
                        </tbody>
                      </table>
                    )}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
            {/* table layout */}
          </div>
        </div>
      </div>
    </>
  );
}
