// import React, { useState, useEffect, useContext } from "react";
// import { Link, Navigate } from "react-router-dom";
// import MyButton from "../componant/MyButton";
// import productContext from "../context/Product/productContext";
// import Noticontext from "../context/notification/NotifContext";
// import "../css/slider.css";
// import ComContext from "../context/Company/CompanyContext";
// // import orderconfirm from "../svgicon/confirmed.svg";
// import InfiniteScroll from "react-infinite-scroll-component";
// import OrderContext from "../context/Order/OrderCon";
// import CustomButton from "../componant/CustomButton";
// import formatDateTime from "../staticFunc/DateFormat";
// import Search_Query from "../componant/search_query/Search_Query";

import React, { useContext, useEffect, useState } from "react";
import Search_Query from "../componant/search_query/Search_Query";
import productContext from "../context/Product/productContext";
import formatDateTime from "../staticFunc/DateFormat";
import InfiniteScroll from "react-infinite-scroll-component";
import "../css/slider.css";
import SettingContext from "../context/Setting/SettingContext";
import CustomButton from "../componant/CustomButton";
//require("dotenv").config();
export default function Inventory1(props) {
  const mycontext = useContext(productContext);
  const {
    Invproduct,
    totalCount,
    getAllProductInventoy,
    totalInventory,
    getAllProduct,
    updateProduct,
    updateInventory,
    InvFilters,
  } = mycontext;
  const { width } = useContext(SettingContext);

  const [showInventoryDialog, setShowInventoryDialog] = useState(false);

  const [editPro, setEditPro] = useState(null);

  const open = (obj) => {
    setShowInventoryDialog(!showInventoryDialog);
    setEditPro(obj);
  };

  useEffect(() => {
    getAllProductInventoy();
    console.log("inventory called", Invproduct);
  }, []);

  //   load more function
  const [loadMore, setLoadMore] = useState(true);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [f_type, setFilterType] = useState("all");
  const fetchFunction = (que) => {
    console.log(
      "load more function called" + Invproduct.length + " - " + totalCount
    );
    if (Invproduct.length === totalCount) {
      setLoadMore(false);
    } else {
      setLoadMore(true);
      setPage(page + 8);
      getAllProductInventoy(true, page + 8, que, f_type, (l_length) => {
        if (l_length === 0) {
          setLoadMore(false);
        }
      });
    }
  };

  const handleFilterChange = async (filterType) => {
    setFilterType(filterType);
     setPage(1);
     getAllProductInventoy(false, 1, query, filterType, (l_length) => {
       if (l_length === 0) {
        //  setLoadMore(false);
       }
     });
  };

  return (
    <>
      {showInventoryDialog && (
        <InventoryDialog
          open={() => {
            setShowInventoryDialog(!showInventoryDialog);
          }}
          alert={(msg) => {
            props.showAlert(msg, "success");
          }}
          data={editPro}
          type={editPro ? editPro.type : "reduce"}
          updateInventory={(sendData, id) => {
            updateInventory(sendData, id, (s) => {
              alert(s.msg);
              open(null);
            });
          }}
        />
      )}
      <div className="content-wrapper inventory_page">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <div className="col">
              <h4 className=" c_heading w-100">
                <span className="">Inventory ({totalInventory})</span>
              </h4>
            </div>

            <div className="col-6">
              <CustomButton
                buttondata={[
                  { title: "ALL", onClick: () => handleFilterChange("all") },
                  {
                    title: "LOW STOCK",
                    onClick: () => handleFilterChange("low_stock"),
                  },
                  {
                    title: "OUT OF STOCK",
                    onClick: () => handleFilterChange("out_of_stock"),
                  },
                  {
                    title: "NEGATIVE STOCK",
                    onClick: () => handleFilterChange("negative_stock"),
                  },
                ]}
                filterfunction={(title)=>{
                  handleFilterChange(title.replace(" ", "_").toLowerCase());  
                }}
                widthM="100%"
              />
            </div>

            <div className="col-lg-12 mb-4 ">
              <div className="card">
                <h5 className="card-header">
                  <div className="row align-items-center">
                    <div className="col-lg-12 text-right">
                      <Search_Query
                        value={query}
                        show={query !== ""}
                        onQuery={setQuery}
                        clear_data={() => {
                          setQuery("");
                          setPage(1);
                          setLoadMore(true);
                          getAllProduct(false, 1, "");
                        }}
                        search_click={() => {
                          getAllProduct(false, 1, query);
                          setPage(1);
                          setLoadMore(true);
                        }}
                        placeholder="Search by Product Name ..."
                      />
                      {/* <input
                        type="text"
                        className="form-control search"
                        placeholder="Search by Product Name, sku, Status, etc..."
                        onChange={(e) => setQuery(e.target.value)}
                      /> */}
                    </div>

                    <div className="col-lg-6 font-bold text-right"></div>
                  </div>
                </h5>

                <div className="table-responsive text-nowrap mb-1">
                  <InfiniteScroll
                    hasMore={loadMore}
                    next={() => {
                      fetchFunction(query);
                    }}
                    dataLength={Invproduct.length}
                    loader={
                      <>
                        <div
                          className="loader"
                          style={{
                            position: "relative",
                            width: 40,
                            height: 40,
                          }}
                        ></div>
                      </>
                    }
                  >
                    <table className="table table-hover">
                      <thead className="order_history_header">
                        <tr>
                          <th>Image</th>
                          <th>Name</th>
                          <th>Price</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="table-border-bottom-0 order_history_body">
                        {Invproduct.map((pro, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <img
                                  src={
                                    pro.p_gallery_image.length !== 0
                                      ? pro.p_gallery_image[0].link
                                      : "../assets/img/avatars/5.png"
                                  }
                                  alt="Avatar"
                                  width="60"
                                  height="60"
                                  className="rounded-circle customer_image my-auto"
                                />
                              </td>
                              <td style={{ textWrap: "wrap" }}>
                                <span className="font-bold">{pro.p_name}</span>
                                <small className="d-block ">
                                  {pro.p_price
                                    ? pro.p_price.name
                                    : "No Attribute"}
                                </small>
                              </td>
                              <td>
                                {pro.p_price
                                  ? pro.p_price.inventory
                                  : pro.inventory}{" "}
                                <small style={{ color: "blue" }}>
                                  {pro.unit_name}
                                </small>
                                <small className="d-block ">
                                  {formatDateTime(pro.inv_update_date)}
                                </small>
                              </td>
                              <td>
                                <span
                                  className="d-block badge bg-danger px-2 mb-2"
                                  style={{ width: "fit-content" }}
                                  onClick={() => {
                                    open({
                                      ...pro,
                                      attr: pro.p_price ? "attr" : "noattr",
                                      attr_id: pro.p_price
                                        ? pro.p_price._id
                                        : "",
                                      type: "reduce",
                                    });
                                  }}
                                >
                                  -
                                </span>
                                <span
                                  className="d-block badge bg-success px-2"
                                  style={{ width: "fit-content" }}
                                  onClick={() => {
                                    open({
                                      ...pro,
                                      attr: pro.p_price ? "attr" : "noattr",
                                      attr_id: pro.p_price
                                        ? pro.p_price._id
                                        : "",
                                      type: "add",
                                    });
                                  }}
                                >
                                  +
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </InfiniteScroll>
                </div>
              </div>
            </div>
            {/* table layout */}
          </div>
        </div>
      </div>
    </>
  );
}

const InventoryDialog = ({
  open,
  data = null,
  alert = null,
  type = "Reduce",
  updateInventory,
}) => {
  const inventory = parseInt(
    data.attr === "noattr" ? data.inventory : data.p_price.inventory
  );
  const [newStock, setNewStock] = useState(inventory);

  const [sendData, setSendData] = useState({
    p_id: data._id,
    attr_id: data.attr === "noattr" ? "" : data.p_price._id,
    inventory: "",
    date: "",
  });

  const onChangeData = (e) => {
    if (e.target.name === "inventory") {
      const value =
        e.target.value === ""
          ? inventory
          : type === "add"
          ? parseInt(e.target.value) + inventory
          : inventory - parseInt(e.target.value);
      setNewStock(value);

      setSendData({ ...sendData, [e.target.name]: value });
    } else {
      setSendData({ ...sendData, [e.target.name]: e.target.value });
    }
  };

  const sendDataFunc = async () => {
    updateInventory(sendData, data.attr);
  };

  return (
    <>
      <div
        className="modal"
        style={{ display: "block", background: "#00000080" }}
      >
        <div className="modal-dialog" style={{ width: "auto" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{type} Inventory</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={open}
              ></button>
            </div>
            <div className="modal-body " style={{ borderTop: "1px dashed" }}>
              <div className="form-group">
                <label htmlFor="p_name" className="form-label">
                  Date
                </label>
                <input
                  type="date"
                  className="form-control   m-0"
                  name="date"
                  id="date"
                  aria-describedby="helpId"
                  onChange={onChangeData}
                />
              </div>

              <div
                className="d-flex justify-content-between my-3"
                style={{ background: "#7272712b" }}
              >
                <span>Current Stock</span>
                <span className="font-bold">
                  {data.attr === "noattr"
                    ? data.inventory
                    : data.p_price.inventory}
                </span>
              </div>

              <div className="form-group mb-3">
                <label htmlFor="p_name" className="form-label">
                  Enter Quantity to {type === "add" ? "increase" : "reduce"}
                </label>
                <input
                  type="number"
                  className="form-control   m-0"
                  name="inventory"
                  id="inventory"
                  aria-describedby="helpId"
                  placeholder={`Quantity to ${data.type}`}
                  onChange={onChangeData}
                />
              </div>

              <div
                className="d-flex justify-content-between my-3"
                style={{
                  background: "#7272712b",
                  color: `${data.type === "add" ? "green" : "red"}`,
                }}
              >
                <span>Updated Stock</span>
                <span className="" style={{ fontWeight: "bold" }}>
                  {newStock}
                </span>
              </div>

              <div className="d-flex">
                <button className="cancel_btn me-2 w-50" onClick={open}>
                  Cancel
                </button>
                <button
                  className="btn btn-primary w-50 orange-btn me-2"
                  onClick={sendDataFunc}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
