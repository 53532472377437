import React, { useContext, useEffect, useState } from "react";
import Search_Query from "../componant/search_query/Search_Query";
import productContext from "../context/Product/productContext";
import CusContext from "../context/Customer/CustomerContext";
import { useParams } from "react-router-dom";
import SettingContext from "../context/Setting/SettingContext";
import SpecificPriceCard from "../MobilePages/SpecificPriceCard";
const SpecificPrice = () => {
  const { width } = useContext(SettingContext);

  const mycontext = useContext(productContext);
  const [t_pro, set_t_pro] = useState(0);
  const {
    Invproduct,

    getAllProduct,
  } = mycontext;
  const [loadMore, setLoadMore] = useState(true);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");

  const cusCon = useContext(CusContext);
  const { GetSpecificPrice, spec_pro } = cusCon;

  const { cus_id } = useParams();
  const user_data = JSON.parse(localStorage.getItem("user_data"));

  useEffect(() => {
    GetSpecificPrice(user_data._id, cus_id, (c) => {
      set_t_pro(c);
    });
  }, []);
  const style = {
    background: "#00000080",
    display: "block",
  };
  const [addSpecModel, setAddSpecModel] = useState(false);
  const [editData, setEditObj] = useState(null);
  const openData = (obj) => {
    if (!addSpecModel) {
      setEditObj(obj);
    }
    setAddSpecModel(!addSpecModel);
  };
  const [type, setType] = useState("Percentage");

  const specData = spec_pro.map((item, index) => {
    const pro = item.product_data;
    const m_price =
      pro.p_price && pro.p_price.length > 0
        ? pro.p_price[0].value
        : parseFloat(pro.price);

    let catlog = item.catdata;

    let discounted_price = catlog.flattDiscount
      ? m_price - parseFloat(catlog.discount)
      : (m_price * (100 - parseFloat(catlog.discount))) / 100;

    let sp_price = 0;

    if (item.sp !== null && item.sp !== "") {
      const spdata = item.sp;
      sp_price = spdata.flattDiscount
        ? m_price - parseFloat(spdata.discount)
        : (m_price * (100 - parseFloat(spdata.discount))) / 100;
      {
        /* catlog.discount = parseFloat(spdata.discount);
                          catlog.flattDiscount = spdata.flattDiscount; */
      }
    }

    return (
      <>
        {width < 767 ? (
          <SpecificPriceCard data={pro} item={item} openData={openData} />
        ) : (
          <tr key={index}>
            <td>
              <img
                src={
                  pro.p_gallery_image.length !== 0
                    ? pro.p_gallery_image[0].link
                    : "../assets/img/avatars/5.png"
                }
                alt="Avatar"
                width="60"
                height="60"
                className="rounded-circle customer_image my-auto"
              />
            </td>
            <td style={{ textWrap: "wrap" }}>
              <span className="font-bold">{pro.p_name}</span>
              <small className="d-block ">
                {pro.p_price.length > 0 ? pro.p_price[0].name : "No Attribute"}
              </small>
            </td>
            <td> ₹{parseFloat(m_price).toFixed(2)} </td>

            <td className="cat-p">
              {" "}
              ₹{parseFloat(discounted_price).toFixed(2)}{" "}
            </td>

            <td> ₹{parseFloat(sp_price).toFixed(2)} </td>
            <td className="action text-center">
              <div className="dropdown">
                <button
                  type="button"
                  className="btn p-0 dropdown-toggle hide-arrow"
                  data-bs-toggle="dropdown"
                >
                  <i className="bx bx-dots-vertical-rounded"></i>
                </button>
                <div className="dropdown-menu">
                  {item.sp !== null && item.sp !== "" ? (
                    <p className="dropdown-item">Reset Price</p>
                  ) : (
                    <p
                      className="dropdown-item"
                      onClick={() => {
                        openData({ name: pro.p_name, cat_obj: item.catdata, mrp:m_price });
                      }}
                    >
                      Change Price
                    </p>
                  )}
                </div>
              </div>
            </td>
          </tr>
        )}
      </>
    );
  });

  const [updatePriceBody, setUpdatePriceBody] = useState({
    flattDiscount: false,
    discount: 0,
    id: "",
    dis_price:0
  });

  const typeChange = (text) => {
     setType(text);
     if (text === "Percentage") {
       setUpdatePriceBody({ ...updatePriceBody, flattDiscount: false });
     } else {
       setUpdatePriceBody({ ...updatePriceBody, flattDiscount: true });
     }
  };

  useEffect(() => {
    if(editData !== null){
      let price = editData.mrp;
      if (updatePriceBody.flattDiscount) {
        price = price - updatePriceBody.discount;
      } else {
        price = (price * (100 - updatePriceBody.discount)) / 100;
      }

      setUpdatePriceBody({ ...updatePriceBody, dis_price: price });
    }

  },[updatePriceBody])
  return (
    <>
      {addSpecModel && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" style={{ width: "auto" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="exampleModalLabel"
                  style={{ color: "blue" }}
                >
                  Specific Price
                </h5>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  // onSubmit={handleClick}
                >
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      <h5 className="catalog_name">
                        {editData && editData.name}
                      </h5>
                      <div className="catalog-price-section d-flex justify-content-between p-1">
                        <span className="dis-div">Catalog Discount</span>
                        <span className="dis-val">
                          {editData && editData.cat_obj.flattDiscount === true
                            ? "$" + editData.cat_obj.discount
                            : parseFloat(editData.cat_obj.discount).toFixed(2) +
                              "%"}
                        </span>
                      </div>
                      <h6 className="spc-di mt-2">SPECIFIC DISCOUNT</h6>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          aria-label="Text input with dropdown button"
                          placeholder="Give discounts on MRP "
                          name="discount"
                          onChange={(e)=>{
                            setUpdatePriceBody({
                              ...updatePriceBody,
                              discount: e.target.value,
                            });
                          }}                       
                        />

                        <button
                          className="btn btn-outline-primary dropdown-toggle my-dropdown"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {updatePriceBody.flattDiscount === true
                            ? "Amount"
                            : "Percentage"}
                        </button>

                        <ul className="dropdown-menu">
                          <li>
                            <p
                              className="dropdown-item"
                              onClick={() => {
                                typeChange("Percentage");
                              }}
                            >
                              Percentage
                            </p>
                          </li>
                          <li>
                            <p
                              className="dropdown-item"
                              onClick={() => {
                                typeChange("Amount");
                              }}
                            >
                              Amount
                            </p>
                          </li>
                        </ul>
                      </div>

                      <div className="specprice-section d-flex justify-content-between p-2">
                        <span className="spc-div">
                          Specific Discounted Price
                        </span>
                        <span className="dis-val">
                          {parseFloat(updatePriceBody.dis_price).toFixed(2)}</span>
                      </div>
                    </div>
                    {/* category add section end */}
                  </div>
                  <div className="mt-3" style={{ textAlign: "right" }}>
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                      onClick={openData}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      // onClick={handleClick}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="content-wrapper inventory_page">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <div className="col">
              <h4 className=" c_heading w-100">
                <span className="">Specific Price ({t_pro})</span>
              </h4>
            </div>

            <div className="col-lg-12 mb-4 ">
              <div className="card">
                <h5 className="card-header">
                  <div className="row align-items-center">
                    <div className="col-lg-12 text-right">
                      <Search_Query
                        value={query}
                        show={query !== ""}
                        onQuery={setQuery}
                        clear_data={() => {
                          setQuery("");
                          setPage(1);
                          setLoadMore(true);
                          getAllProduct(false, 1, "");
                        }}
                        search_click={() => {
                          getAllProduct(false, 1, query);
                          setPage(1);
                          setLoadMore(true);
                        }}
                        placeholder="Search by Product Name ..."
                      />
                    </div>

                    <div className="col-lg-6 font-bold text-right"></div>
                  </div>
                </h5>

                <div className="table-responsive text-nowrap mb-1">
                  {width < 767 ? (
                    specData
                  ) : (
                    <table className="table table-hover">
                      <thead className="order_history_header">
                        <tr>
                          <th>Image</th>
                          <th>Name</th>
                          <th>MRP</th>
                          <th>Catalog Price</th>
                          <th>Specific Price</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="table-border-bottom-0 order_history_body">
                        {specData}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
            {/* table layout */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecificPrice;
