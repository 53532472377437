import React from "react";

const Search_Query = ({
  value = "",
  show = false,
  onQuery,
  clear_data,
  search_click,
  placeholder = "Search Category...",
}) => {
  return (
    <div style={sytle.container}>
      <input
        type="text"
        className="form-control search m-0"
        placeholder={placeholder}
        onChange={(e) => {
          onQuery(e.target.value);
        }}
        value={value}
      />
      <div
        style={sytle.button_container}
        className={`${show ? "d-block" : "d-none"}`}
      >
        <span
          className="mx-1"
          style={{
            background: "#727271",
            padding: "5px 10px",
            borderRadius: 10,
            color: "white",
            cursor: "pointer",
          }}
          onClick={clear_data}
        >
          Clear
        </span>
        <span className="mx-1" style={sytle.btn_btn} onClick={search_click}>
          Search
        </span>
      </div>
    </div>
  );
};

const sytle = {
  container: {
    position: "relative",
  },
  button_container: {
    position: "absolute",
    top: 0,
    bottom: 0,
    margin: "auto",
    right: "3%",
    height: "fit-content",
    fontSize: "12px",
  },
  btn_btn: {
    background: "#ef6817",
    padding: "5px 10px",
    borderRadius: 10,
    color: "white",
    cursor: "pointer",
  },
};

export default Search_Query;
