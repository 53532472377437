import React, { useState, useEffect, useContext } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import MyButton from "../componant/MyButton";
import productContext from "../context/Product/productContext";
import Noticontext from "../context/notification/NotifContext";
import "../css/slider.css";
import ComContext from "../context/Company/CompanyContext";
// import orderconfirm from "../svgicon/confirmed.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import OrderContext from "../context/Order/OrderCon";
import CustomButton from "../componant/CustomButton";
import formatDateTime from "../staticFunc/DateFormat";
import Search_Query from "../componant/search_query/Search_Query";
import SettingContext from "../context/Setting/SettingContext";
import OrderCard from "../MobilePages/OrderCard";
//require("dotenv").config();
export default function Order(props) {
  const context = useContext(ComContext);
  const { checkcompany } = context;

  // if (localStorage.getItem("com_id") === null) {
  //   return <Navigate to="/mycompanies" />;
  // }
  const navigate = useNavigate();
  checkcompany();
  const host = process.env.REACT_APP_URL;

  const notiContext = useContext(Noticontext);
  const orderContext = useContext(OrderContext);

  const { myOrders, setMyOrders, totalOrder, getAllOrders, orderStatusChange } =
    orderContext;

  const { notification, getAllNotificaiton, pushNotification } = notiContext;
  const { width } = useContext(SettingContext);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      getAllOrders();
      getAllProduct();
    }
  }, []);

  // product section
  const productcontext = useContext(productContext);
  const { product, getAllProduct } = productcontext;

  const GetproductDetail = (id) => {
    const matchProduct = product.filter((pro) => {
      return pro._id === id.product_id;
    });
    return matchProduct;
  };

  const changeStatus = async (orderid, statusType, str_id) => {
    // const requestOptions = {
    //   method: "PUT",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "auth-token": localStorage.getItem("token"),
    //   },
    // };
    // const response = await fetch(
    //   `${host}/api/order/changeOrderStatus/${orderid}/${statusType}/${str_id}`,
    //   requestOptions
    // );
    // const datares = await response.json();
    const body = {
      body: "Order Status has been Changed",
      type: "Ordernotification",
      id: "65a1018a160aac86473dce7f",
      name: "vishnat",
      itemL: 2,
      total_value: "10",
      tag: "Order Packed",
    };

    const data = { id: "65a1099e160aac86473dcfa0", data: body };
    // pushNotification(data);
    orderStatusChange(orderid, statusType, str_id, (status, datares) => {
      if (status === "success") {
        props.showAlert(datares.msg, "success");
        pushNotification(
          datares.data.userid,
          "order",
          "Change Order Status",
          datares.msg
        );
        getAllOrders();
      } else {
        props.showAlert(datares.msg, "danger");
      }
    });
  };

  const [query, setQuery] = useState("");
  const [loadMore, setLoadMore] = useState(true);
  const [filter_type, setFilterType] = useState("all");

  const fetchMoreData = (page_no = 1, filter_data = filter_type) => {
    console.log(
      "fetch more data called = " + totalOrder + " - " + myOrders.length
    );
    if (totalOrder > myOrders.length) {
      setPage(page_no);
      getAllOrders(true, page_no, query, filter_data, (len) => {
        if (len === 0 && totalOrder > myOrders.length) {
          // setPage(page + 8);
          // getAllOrders(true, page + 16, "", filter_type);
          // fetchMoreData(page_no + 8, filter_data);
        } else {
        }
      });
    } else {
      setLoadMore(false);
    }
  };

  useEffect(() => {
    try {
      getAllOrders(false, 1, "", filter_type, (len) => {
        //   if (len === 0) {
        //     // setPage(page + 8);
        //     // getAllOrders(true, page + 16, "", filter_type);
        //     // setTimeout(() => {
        //     fetchMoreData(9, filter_type);
        //     // }, 3000);
        //   } else {
        //   }
      });
    } catch (error) {
      console.error("Error fetching orders with filter:", error);
    }

    // setLoadMore(false);
  }, [filter_type]);

  const go_detail = (id) => {
    navigate(`/ordersDetails/pts/${id}`);
  };

  const Odr_data = myOrders.map((or, ind) => {
    const date = formatDateTime(or.createdAt).split(",");

    const totalOrder = parseFloat(or.total_price).toFixed(2);

    let status_color = "#273D8E";
    switch (or.order_status) {
      case "pending":
        status_color = "#273D8E";
        break;
      case "confirmed":
        status_color = "#FDD835";
        break;
      case "packed":
        status_color = "#312113";
        break;
      case "dispatched":
        status_color = "#8900CFE0";
        break;
      case "delivered":
        status_color = "#49A752";
        break;
      case "rejected":
      case "cancelled":
        status_color = "#EA2B2B";
        break;
      default:
        status_color = "#000";
    }

    return (
      <>
        {width < 767 ? (
          <OrderCard data={or}/>
        ) : (
          <tr key={ind}>
            <td onClick={() => go_detail(or._id)}>{ind + 1}</td>
            <td onClick={() => go_detail(or._id)}>#{or.f_order_id}</td>
            <td onClick={() => go_detail(or._id)}>
              {date[0]}
              <br />
              <small>{date[1]}</small>
            </td>
            <td onClick={() => go_detail(or._id)}>
              <b>{or.userid.fullname}</b>
              <br />
              <span>
                {or.user_comdata ? or.user_comdata.com_name : "Company - N/A"}
              </span>
              <br />
              <span>{or.userid.phone}</span>
            </td>
            <td onClick={() => go_detail(or._id)}>{or.order_list.length}</td>
            <td onClick={() => go_detail(or._id)}>₹{totalOrder}</td>
            <td onClick={() => go_detail(or._id)}>{or.create_by}</td>
            <td onClick={() => go_detail(or._id)}>
              <span
                className="badge w-100"
                style={{ background: `${status_color}` }}
              >
                {or.order_status === "pending"
                  ? "New Order"
                  : or.order_status === "confirmed"
                  ? "In Process"
                  : or.order_status}
              </span>
            </td>
            <td className="action">
              {or.order_status === "pending" ? (
                <ul className="acceptOrder d-flex justify-content-around">
                  <li
                    className="btn orange-btn"
                    onClick={() =>
                      changeStatus(or.order_id, "confirmed", or._id)
                    }
                  >
                    Accept
                  </li>
                  <li
                    className="btn"
                    onClick={() =>
                      changeStatus(or.order_id, "rejected", or._id)
                    }
                  >
                    Reject
                  </li>
                </ul>
              ) : or.order_status === "reject" ? (
                <ul className="acceptOrder">
                  <li className="btn">Rejected</li>
                </ul>
              ) : (
                <div className="form-group">
                  <select
                    className="form-control"
                    name="status_type"
                    onChange={(e) =>
                      changeStatus(or.order_id, e.target.value, or._id)
                    }
                  >
                    {or.order_status === "confirmed" && (
                      <option value="confirmed">In Process</option>
                    )}
                    {(or.order_status === "packed" ||
                      or.order_status === "confirmed") && (
                      <option value="packed">Packed</option>
                    )}
                    {(or.order_status === "dispatched" ||
                      or.order_status === "packed") && (
                      <option value="dispatched">Dispatched</option>
                    )}
                    {(or.order_status === "dispatched" ||
                      or.order_status === "delivered") && (
                      <option value="delivered">Delivered</option>
                    )}
                    <option value="cancelled">Cancelled</option>
                  </select>
                </div>
              )}
            </td>
          </tr>
        )}
      </>
    );
  });

  return (
    <>
      {/* <div className="content-wrapper order_container">
        <div className="container-fluid container-xxl flex-grow-1 py-3"> */}

      <div className="content-wrapper order_page order_container">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-12">
              <h4 className="c_heading">
                <span>My Orders ({totalOrder})</span>
                <div className="mt-3">
                  <CustomButton
                    buttondata={[
                      { title: "ALL" },
                      { title: "TODAY" },
                      { title: "NEW ORDER" },
                      { title: "IN PROCESS" },
                      { title: "PACKED" },
                      { title: "DISPATCHED" },
                      { title: "DELIVERED" },
                      { title: "CANCELLED" },
                      { title: "REJECTED" },
                    ]}
                    filterfunction={async (filter) => {
                      let filter_type = "all";
                      switch (filter.toLowerCase()) {
                        case "new order":
                          filter_type = "pending";
                          break;
                        case "in process":
                          filter_type = "confimed";
                          break;
                        case "packed":
                          filter_type = "packed";
                          break;
                        case "dispatched":
                          filter_type = "dispatched";
                          break;
                        case "delivered":
                          filter_type = "delivered";
                          break;
                        case "cancelled":
                          filter_type = "cancelled";
                          break;
                        case "rejected":
                          filter_type = "rejected";
                          break;
                        default:
                          filter_type = "all";
                          break;
                      }
                      setMyOrders([]);
                      setPage(1);
                      setFilterType(filter_type);
                      setLoadMore(true);
                    }}
                    widthM="100%"
                  />
                </div>
              </h4>
            </div>

            <div className="col-12 mb-4">
              <div
                className="card"
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <h5 className="card-header">
                  <div className="row align-items-center">
                    <div className="col-12 text-end">
                      <Search_Query
                        value={query}
                        show={query !== ""}
                        onQuery={setQuery}
                        placeholder="Search by Product Name, Customer Name, etc..."
                        clear_data={() => {
                          setQuery("");
                          setPage(1);
                          getAllOrders(false, 1, "", filter_type, () => {});
                        }}
                        search_click={() => {
                          setPage(1);
                          getAllOrders(false, 1, query, filter_type, () => {});
                          setLoadMore(true);
                        }}
                      />
                    </div>
                  </div>
                </h5>

                <div className="table-responsive text-nowrap mb-1">
                  <InfiniteScroll
                    hasMore={loadMore}
                    next={() => fetchMoreData(page + 10, filter_type)}
                    dataLength={myOrders.length}
                    loader={
                      <div
                        className="loader"
                        style={{
                          position: "relative",
                          width: 40,
                          height: 40,
                        }}
                      ></div>
                    }
                  >
                    {/* responsive logic  */}

                    {width < 767 ? (
                      Odr_data
                    ) : (
                      <table className="table table-hover">
                        <thead className="order_history_header">
                          <tr>
                            <th>S.No</th>
                            <th>Order ID</th>
                            <th>Date</th>
                            <th>Customer Name</th>
                            <th>Items</th>
                            <th>Order Value</th>
                            <th>Create By</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="table-border-bottom-0 order_history_body">
                          {Odr_data}
                        </tbody>
                      </table>
                    )}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
