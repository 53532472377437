import React from "react";
import { useNavigate } from "react-router-dom";

const Customerscard = ({
  data,
  setEdit_customer,
  setModal,
  set_edit_object,
  deleteCustomer,
  setCustomer,
  showCon,
  updateStatus,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className=" card p-2 my-2">
        <div className="row">
          <div className="col-3">
            <div className="cus-img">
              <img
                src={
                  data.com_data && data.com_data.com_image !== ""
                    ? data.com_data.com_image
                    : "../assets/img/avatars/5.png"
                }
                className="cus-image"
                alt="Cinque Terre"
              />
            </div>
          </div>
          <div className="col-7">
            <div
              className="cus-data d-flex flex-column my-2"
              style={{ borderBottom: "1px solid #ebe7e7" }}
            >
              <span className="cus-name">{data.nick_name}</span>
              <span className="cus-name">
                {data.com_data ? data.com_data.com_name : "Company - N/A"}
              </span>
              <span className="cus-hn mb-2">{data.customer_id.phone}</span>
            </div>
            <span className="outstan">
              Outstanding Balace :₹{" "}
              {parseInt(data.out_standing) < 0
                ? parseFloat(data.out_standing).toFixed(2) + " (Adv)"
                : parseFloat(data.out_standing).toFixed(2)}
            </span>
          </div>

          <div className="dro">
            <div className="dropdown cus">
              <button
                type="button"
                className="btn p-0 dropdown-toggle hide-arrow"
                data-bs-toggle="dropdown"
              >
                <i
                  className="bx bx-dots-vertical-rounded "
                  style={{ color: "black" }}
                ></i>
              </button>
              <div className="dropdown-menu">
                <p
                  className="dropdown-item"
                  onClick={() => {
                    navigate("/createorder/supplier/" + data.customer_id._id);
                  }}
                >
                  Create Order
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => {
                    navigate("/specific_price/" + data.customer_id._id);
                  }}
                >
                  Specific Prices
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => {
                    navigate("/payments", {
                      state: { id: data.customer_id._id },
                    });
                  }}
                >
                  Payments
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => {
                    setEdit_customer(true);
                    // setModal(true);
                    set_edit_object(data);
                    setCustomer({
                      id: data.customer_id._id,
                      fullname: data.nick_name,
                    });
                  }}
                >
                  Edit
                </p>
                <p
                  className="dropdown-item"
                  oonClick={async () => {
                    const res = await updateStatus(
                      "block",
                      data.customer_id._id
                    );
                  }}
                >
                  Block
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => {
                    showCon(data.customer_id._id);
                  }}
                >
                  Delete
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customerscard;
